.products-component {
  width: 100%;
  min-height: 100vh;
  overflow: hidden;
  /* display: grid;
  grid-template-columns: 0.5fr 1fr; */
  display: flex;
  /* align-items: center; */
}
.products-component > div {
  width: 50%;
  height: 100%;
  /* overflow: hidden; */
  /* max-height: 100vh; */
}
.products-component > .products-component-left {
  width: 55%;
}
.products-component {
  background-color: #f5f5f5;
}
.products-component-left {
  background-color: #f5f5f5;
  padding: 60px;
}
.products-component-left h2 {
  font-size: 38px;
}
.preview-item {
  position: relative;
}
.tags {
  position: absolute;
  right: 20px;
  top: 20px;
}
.preview-container {
  width: 85%;
}

.products-component {
  display: flex;
}

.products-component-left {
  /* flex: 1; */
}

.products-component-right {
  flex: 1;
}

.tabs-preview {
  margin-top: 10px;
}
.tab-item {
  cursor: pointer;
  margin: 10px 0;
}
.tab-item p {
  font-size: 17px;
  opacity: 0.6;
  letter-spacing: -0.5px;
}

.preview-container {
  margin-top: 20px;
}

.preview-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto auto;
  gap: 10px;
}

.preview-item.full-width {
  grid-column: span 2;
}

.preview-item.half-width {
  grid-column: span 1;
}

.preview-item > img {
  width: 100%;
  height: 100%;
}
.tabs-preview {
  display: flex;
  gap: 15px;
  align-items: center;
}
.products-component-right-img {
  width: 100%;
  height: 100%;
}
.products-component-right-img img {
  width: 100%;
  height: 100%;
  display: flex;
  min-height: 100vh;
  object-fit: cover;
}
.preview-item {
  cursor: pointer;
  overflow: hidden;
  border-radius: 12px;
}
.active-tab p {
  font-weight: 600;
  opacity: 0.8;
  text-decoration: underline;
  line-height: 150%;
}
.preview-info {
  position: absolute;
  top: 20px;
  right: 10px;
  display: flex;
  gap: 10px;
  align-items: center;
}
.preview-info-item {
  font-size: 12px;
  font-weight: 500;
  background-color: #fff;
  border-radius: 4px;
  padding: 5px 6px;
}
.secondary-preview-info-item {
  background-color: transparent;
  border: 1px solid #fff;
  color: #fff;
}
.products-component-right {
  position: relative;
}
.products-component-right-content {
  position: absolute;
  bottom: 150px;
}
.products-component-right-content h1 {
  font-weight: 200 !important;
  font-size: 42px;
  color: #fff;
}
.products-component-right-content p {
  font-size: 22px;
  color: #fff;
  margin-top: 40px;
}
.products-component-right-content {
  padding: 0 30px;
}
.products-right-content-logo img {
  position: absolute;
  top: 60px;
  width: 250px;
  padding: 0 40px;
}

.infinite-scroll {
  width: 90%;
  margin: 0 auto;
  padding-top: 50px;
  /* margin-bottom: 20px; */
  overflow: hidden;
  -webkit-mask-image: linear-gradient(
    90deg,
    transparent,
    #fff 90px,
    #fff calc(100% - 90px),
    transparent
  );
  mask-image: linear-gradient(
    90deg,
    transparent,
    #fff 90px,
    #fff calc(100% - 90px),
    transparent
  );
}
.scrolling-logos {
  display: flex;
  width: 300%;
  gap: 5rem;
  overflow: hidden;
  animation: carousel 30s linear infinite;
  white-space: nowrap;
  align-items: center;
}

@keyframes carousel {
  0% {
    transform: translateX(0%);
  }
  50% {
    transform: translateX(calc(-100% / 9 * 1));
  }
  100% {
    transform: translateX(calc(-100% / 9 * 2));
  }
}
.mobile-tab {
  display: none;
}

.preview-logo img {
  position: absolute;
  bottom: 20px;
  width: 100px;
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  padding: 7px 10px;
  right: 20px;
  display: flex;
  align-items: center;
}
@media (max-width: 1300px) {
  .products-component-right-content h1 {
    font-size: 36px;
  }
  .products-component-right-content p {
    font-size: 18px;
  }
  .products-component-right-content {
    bottom: 60px;
  }
  .products-wrapper {
    margin-top: 60px;
  }
  .tab-item p {
    font-size: 15px;
  }
  .products-right-content-logo img {
    width: 180px;
  }
  .products-component-left {
    padding: 30px;
  }
  .products-component-left h2 {
    font-size: 28px;
  }
  .products-component > .products-component-left {
    width: 50%;
  }
  .products-component-left h2 p {
    display: inline;
    padding-left: 2px;
  }
}
@media (max-width: 800px) {
  .scrolling-logos {
    gap: 2.5rem;
    width: 900%;
  }
}
@media (max-width: 900px) {
  .tab-item p {
    display: none;
  }
  .preview-container {
    width: 95%;
  }
  .preview-grid > div {
    width: 33%;
  }
  .products-component-right-img img {
    min-height: unset;
  }
  .products-component-right-content h1 {
    font-size: 24px;
  }
  .products-component-right-content p {
    font-size: 15px;
    /* truncate */
  }
  .preview-container {
    margin-top: 10px;
  }
  .products-component-right-content {
    padding: 0 15px;
  }
  .products-right-content-logo img {
    padding: 0 15px;
    width: 120px;
  }
  .products-component-right-content p {
    margin-top: 20px;
  }
  .tab-item .mobile-tab {
    display: block;
  }
  .products-component {
    flex-direction: column;
  }
  .products-component > div {
    width: 100% !important;
  }
  .preview-grid {
    display: flex;
  }
  .preview-info {
    display: none;
  }
  .products-wrapper {
    margin-top: 140px;
  }
  .products-component{
    padding-top: 30px;
  }
  .products-component-left h2 {
    font-size: 26px;
  }
  .tabs-preview {
    gap: 7px;
  }
  .tabs-preview p {
    font-size: 14px;
    white-space: nowrap;
  }
  .products-component-left {
    padding: 15px;
  }

  .preview-logo img {
    position: absolute;
    bottom: 10px;
    width: 60px;
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    padding: 5px 10px;
    right: 5px;
    display: flex;
    align-items: center;
  }
}
