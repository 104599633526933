@import url("https://fonts.cdnfonts.com/css/helvetica-neue-55");
.founders-page {
  background-color: #070707;
  color: #fff;
}
.founders-section-1 {
  width: 90%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 100%;
}
.founders-section-1-flex {
  /* padding-top: 120px; */
  display: flex;
  align-items: center;
  gap: 30px;
  justify-content: space-between;
}
.founders-section-1 {
  color: #fff;
}
.founders-page {
  font-family: "Helvetica Neue", sans-serif;
}
.founders-section-1 {
  min-height: 100vh;
}
.founders-section-text h1 {
  font-size: 82px;
  font-weight: 400;
  letter-spacing: -1px;
  color: #fff;
}
.founders-section-text h1 span {
  color: #fff;
  color: #3091d7;
}
.founders-section-animation {
  width: 35%;
}
.founders-section-text p {
  color: #9f9d9d;
  font-size: 22px;
  line-height: 1.5;
  margin-top: 5px;
  padding-right: 15%;
}
.founders-section-text p span {
  color: #fff;
}
.button {
  background-color: #fff;
  color: #000;
  font-weight: 500;
  letter-spacing: -1px;
  padding: 10px 20px;
  border-radius: 125px;
  border: none;
  font-size: 18px;
  margin-top: 20px;

  text-decoration: none;
}
.founder-section-text-below {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  margin-top: 20px;
  color: #909090;
  width: 100%;
}
.founder-section-text-below p {
  width: 40%;
  font-size: 18px;
}
.founder-section-text-below span {
  color: #fff;
}
.card img {
  margin-bottom: 6px;
}
.founders-section-2 {
  width: 90%;
  margin: 0 auto;
  gap: 50px;
  display: grid;
  min-height: 100vh;
  height: 100%;
  align-items: center;
  justify-content: center;
  grid-template-columns: repeat(2, 1fr);
}
.founders-section-2-text > span {
  color: #909090;
  font-size: 18px;
}
.founders-section-2-text h1 {
  font-size: 50px;
  font-weight: 400;
  letter-spacing: -1px;
  color: #fff;
}
.founders-section-2-text h1 span {
  color: #3091d7;
}
.founders-section-2-text p {
  color: #9f9d9d;
  font-size: 18px;
  margin-top: 10px;

  margin-top: 5px;
}

.carousel-container {
  overflow: hidden;
  height: 300px; /* Adjust height as needed */
}

.carousel-content {
  display: flex;
  flex-direction: column;
  transition: transform 0.5s ease-in-out;
}
.founders-section-3 {
  width: 90%;
  margin: 0 auto;
}
.founders-section-3 h1 {
  font-size: 50px;
  font-weight: 400;
  letter-spacing: -1px;
  color: #fff;
}
.founders-section-3 h1 span {
  color: #3091d7;
}
.founders-section-3 p {
  color: #9f9d9d;
  font-size: 18px;
  margin-top: 10px;
}
.foundation-cards {
  display: grid;
  margin-top: 50px;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
}
.foundation-card h1 {
  font-size: 28px;
}
.foundation-card p {
  margin-top: 10px;
  font-size: 16px;
  color: #9f9d9d;
}
.founders-section-4 {
  width: 90%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 50px;
}
.founders-section-4-left {
  width: 40%;
}
.founders-section-4-right {
  width: 40%;
}
.founders-section-4-right h1 {
  font-size: 28px;
  font-weight: 400;
  letter-spacing: -1px;
  color: #fff;
}
.testimonials-name-flex {
  display: flex;
  gap: 5px;
  align-items: flex-start;
  margin-top: 20px;
}
.testimonials-name-flex p {
  font-size: 20px;
}
.founders-testimonials {
  margin-top: 50px;
}
.testimonials-name-flex span {
  color: #3091d7;
}
.testimonials-name-flex img {
  width: 60px;
}
.founders-section-4-left h1 {
  font-size: 50px;
  font-weight: 400;
  letter-spacing: -1px;
  color: #fff;
}
.founders-section-4 {
  min-height: 100vh;
}
.founders-section-4-left span {
  color: #3091d7;
}
.form {
  width: 100%;
}
.form input {
  width: 100%;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #4a4a4a;
  margin-top: 50px;
  padding-bottom: 5px;
  font-size: 18px;
  color: #777777;
  padding-left: 5px;
}
.form input::placeholder {
  color: #777777;
  font-size: 18px;
}
.form input:focus {
  outline: none;
  border-bottom: 1px solid #fff;
}

.carousel-item {
  height: 300px;
}
.founders-section-2-card {
  position: relative;
  background-color: #0b0b0b;
  border-radius: 18px;
  padding: 12px;
  overflow: hidden;
  border: 1px solid rgba(46, 46, 46, 0.6);
  height: 600px;
}
.wrapper {
  position: absolute;
  top: 260px;
  width: 100%;
}
.wrapper .outer {
  display: flex;
  align-items: center;
  justify-content: center;
}
.wrapper .card {
  width: 450px;

  min-height: 100px;
  border-radius: 12px;
  padding: 20px;
  padding-left: 40px;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  justify-content: space-between;
  animation: animate 15s linear infinite;
  animation-delay: calc(3s * var(--delay));

  background-color: #0d0d0d;
  border: 1px solid #242424;
}
.outer:hover .card {
  animation-play-state: paused;
}
.wrapper .card:last-child {
  animation-delay: calc(-3s * var(--delay));
}
.card h1 {
  font-size: 20px;
  color: #fff;
  font-weight: 400;
  margin-top: 6px;
}
.card p {
  font-size: 14px;
  color: #9f9d9d;
}
@keyframes animate {
  0% {
    opacity: 0.2;
    transform: translateY(100%) scale(0.5);
  }
  5%,
  20% {
    opacity: 0.4;
    transform: translateY(100%) scale(0.7);
  }
  25%,
  40% {
    opacity: 1;
    pointer-events: auto;
    transform: translateY(0%) scale(1);
  }
  45%,
  60% {
    opacity: 0.4;
    transform: translateY(-100%) scale(0.7);
  }
  65%,
  100% {
    opacity: 0;
    transform: translateY(-100%) scale(0.5);
  }
}

.card a:hover {
  transform: scale(0.94);
}

.founders-nav {
  position: fixed;
  top: 0;
  width: 100%;
  background-color: #070707;
  z-index: 10;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 5%;
}
.links {
  display: flex;
  gap: 20px;
  align-items: center;
}

.links p{
    font-size: 16px;
    color: #909090;
    font-weight: 500;
    cursor: pointer;
}
.links p:hover{
    color: #fff;
}
.founders-page button{
    cursor: pointer;
}