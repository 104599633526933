.white-paper-containers {
  /* border: 1px solid blue; */
  width: 100vw;
  /* overflow: hidden; */
  margin-top: 40px;
}

.white-style-title2 {
  font-size: 46px;

  line-height: 136%;
  letter-spacing: -2px;
  font-family: "Poppins", sans-serif;

  font-weight: 500;
}

.white-paper-container {
  /* border: 10px solid yellow; */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  max-width: 1000vw;
  margin: 0 0 0 30px;
  /* padding-bottom: 100px; */
  flex-wrap: wrap;
  box-sizing: border-box;
}

.white-paper-text-gap{
  /* border: 1px solid blue; */
  margin-top: 10px;
}

.white-paper-card-left {
  /* border: 1px solid red; */
  flex: 0 0 40%;
  max-width: 30%;
  width: 100%;
  box-sizing: border-box;
  /* max-height: calc(100vh - 70px); */
}

.white-paper-card-left-text-card {
  /* border: 1px solid blue; */
  background-color: #ffffff;
  width: 100%;
}

.white-paper-card-left-text-card-1 {
  /* border: 1px solid blue; */
  text-align: left;
  padding: 30px 30px 30px 0;
  gap: 20px;
  margin-top:30px;
  margin-bottom:30px;
}

.white-paper-paragraph {
  text-align: left;
  color: #00000080;
  font-family: Poppins, sans-serif;
  font-size: 16px;
  font-weight: 500;
  width: 100%;
}

.white-paper-paragraph > span {
  text-decoration: underline;
  align-items: center;
  gap: 10px;
  background-image: linear-gradient(90deg, #5382ed, #ac6eb3, #d36679);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.white-paper-card-left-item {
  /* border: 1px solid blue; */
  width: 100%;

  /* border: 1px solid red;
    display: flex;
    flex-direction: row;
    height: unset;
    gap: 20px;
    overflow: scroll; */
}
.white-paper-card-left-item-1 {
  border: 2px solid transparent; /* Change to transparent border */
  border-radius: 10px;
  background-image: linear-gradient(white, white), linear-gradient(90deg, #5382ed, #ac6eb3, #d36679);
  background-origin: border-box;
  background-clip: content-box, border-box;
  display: flex;
  gap: 10px;
  cursor: pointer;
  margin-bottom: 20px;
}

.white-paper-card-left-item-1 img {
  /* border: 1px solid blue; */
  width: 30%;
}

.white-paper-card-left-item-1 p {
  padding-top: 20px;
  font-size: 15px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  color: #000;
  line-height: 136%;
  letter-spacing: -0.5px;
  text-align: left;
}

.white-reports {
  /* border: 1px solid blue; */
  background-color: #f5f5f5;
  width: 100%;
  flex: 0 0 60%;
  max-width: 65%;
  max-height: 100vh;
  /* max-height: calc(100vh - 90px); */
  /* height: 100vh; */
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.white-paper-card-right {
  /* border: 1px solid blue; */
  width: 90%;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
  overflow-y: auto;
  display: flex;
  justify-content: center;
  margin: 30px auto 0px auto;
  /* max-height: 90%; */
  border-radius: 10px;
  margin-left: 30px;

  max-height: 75%;
}

.white-paper-card-right-text {
  /* border: 1px solid red; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* margin: auto; */
  width: 90%;
  margin: 20px 20px 0 -10px;
}

.white-paper-card-right-text button {
  padding: 8px 14px;
  margin-bottom: 40px;
  border: none;
  border-radius: 10px;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: -0.5px;
  gap: 5px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  cursor: pointer;
  transition: 0.3s;
  background-color: #0040ff;
  color: #ffffff;
  cursor: pointer;
  width: 30%;
}

.white-paper-card-right-text button img {
  width: 20px;
}

.white-paper-card-right-text p {
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  color: #5a5a5a;
  line-height: 136%;
  letter-spacing: -0.5px;
  text-align: right;
}

.white-paper-card-right-text p span {
  color: #5a5a5a;
  font-size: 14px;
}


/* @media (max-width: 1300px) {
 
}

@media (max-width: 800px) {}

@media (max-width: 600px) {
  .white-paper-container {
    border: 1px solid blue;
    display: flex;
    flex-direction: column;
  }
  .white-paper-card-left {
    border: 1px solid red;
    flex: 1;
    max-width: 100%;
  }
  .white-paper-card-left-text-card-1 {
    border: 1px solid blue;
    text-align: center;
    padding: 30px 30px 30px 0;
  }
  .white-paper-card-left-text-card-1 {
    border: 1px solid blue;
    text-align: center;
  }
} */


@media (max-width: 1300px) {
  .white-paper-card-left-item-1 img {
    width: 200px;
  }
  .white-papers {
    grid-template-columns: 1fr 0.4fr;
  }
  .white-paper-card-left-item-1 {
    flex-direction: column;
  }
  .white-paper-card-left-item-1 {
    width: 210px;
  }
  .white-paper-card-left-item-1 img {
    width: 100%;
  }
  .white-paper-card-left-item-1 p {
    padding-right: 10px;
    margin-top: 0;
    padding-left: 5px;
    padding-bottom: 5px;
    padding-top: 0;
  }


}
@media (max-width: 1200px) {
  .white-papers {
    display: flex;
    flex-direction: column;
    gap: 30px;
  }
  .white-paper-card-right {
    height: 400px;
  }
  .white-paper-card-left-item-1 {
    min-width: 200px;
    padding: 10px;
  }
  .white-paper-card-right-text p {
    font-size: 14px;
  }
  .white-paper-card-right-text button {
    font-size: 14px;
    padding: 8px 20px;
  }
  .white-paper-card-right-text p span {
    font-size: 13px;
  }
  .white-paper-card-right-text {
    align-items: unset;
  }
  .white-paper-card-right-text button {
    width: fit-content;
    justify-content: flex-start;
    text-align: left;
  }
  .white-paper-card-right-text p {
    width: 100%;
    text-align: left;
    justify-content: flex-end;
  }
  .white-paper-card-right-text {
    flex-direction: column;
  }
  .white-paper-card-left-item-1 img {
    width: 50px;
    height: 50px;
    object-fit: cover;
    border-radius: 50%;
  }
  .white-paper-card-left-item-1 {
    display: flex;
    flex-direction: row;
  }
  .white-paper-card-left-item-1 p {
    font-size: 12px;
  }
  .white-papers-left {
    /* border: 1px solid red; */
    display: flex;
    flex-direction: row;
    height: unset;
    gap: 20px;
    overflow: scroll;
    width: 100%;
  }
}

@media (max-width: 800px) {
  .white-paper-container {
    /* border: 1px solid blue; */
    display: flex;
    flex-direction: row;
    height: 120vh;
    justify-content: space-between;
    width: 98%;
    max-width: auto;
    margin: 5px;
    padding-bottom: 10px;
    flex-wrap: wrap;
    box-sizing: border-box;
  }
  .white-paper-card-left {
    /* border: 1px solid red; */
    flex: 1;
    max-width: 100%;
    width: 100%;
    box-sizing: border-box;
  }
  .white-paper-card-left-item {
    /* border: 1px solid blue; */
    width: 100%;
      display: flex;
      flex-direction: row;
      height: unset;
      gap: 20px;
      /* overflow: scroll; */
      overflow-x: scroll; /* Ensure horizontal scroll */
  }
  /* .white-reports {
    border: 1px solid blue;
    width: 100%;
    flex: 1;
    max-width: 100%;
    max-height: 100%;
    overflow-y: auto;
  } */

  .white-reports {
    /* border: 1px solid blue; */
    width: 100%;
    flex: 1;
    max-width: 100%;
    max-height: 100%;
    /* overflow-y: auto;  */
  }

  .white-paper-card-right {
    /* border: 1px solid blue; */
    width: 100%;
    max-height: 100vh;
  }

  .white-paper-card-right {
    width: 100%;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
    overflow-y: auto; /* Ensure content inside is scrollable */
    display: flex;
    justify-content: center;
    margin: 30px auto 30px auto;
    border-radius: 10px;
  }

  .white-paper-card-right-text {
    /* border: 1px solid red; */
    align-items: unset;
    width: 100%;
    margin-bottom: 20px;
    /* display: flex;
    flex-direction: column;
    justify-content: center;
    margin: auto;
    text-align: center; */
  }

  .white-paper-card-right-text button {
    margin-bottom: 10px;
  }
  
  .white-paper-card-right-text p {
    text-align: left;
  }
  .white-style-title2{
    font-size: 28px;
  }

}

@media (max-width: 600px) {
  .white-paper-container {
    /* border: 1px solid blue; */
    display: flex;
    flex-direction: row;
    /* height: 120vh; */
    height: auto;
    justify-content: space-between;
    width: 98%;
    max-width: auto;
    margin: 5px;
    padding-bottom: 10px;
    flex-wrap: wrap;
    box-sizing: border-box;
  }
  .white-paper-card-left {
    /* border: 1px solid red; */
    flex: 1;
    max-width: 100%;
    width: 100%;
    box-sizing: border-box;
  }
  .white-paper-card-left-item {
    /* border: 1px solid blue; */
    width: 100%;
      display: flex;
      flex-direction: row;
      height: unset;
      gap: 20px;
      margin-top: -30px;
      overflow-x: scroll; /* Ensure horizontal scroll */
      margin-left: 10px;
  }

  .white-reports {
    /* border: 1px solid blue; */
    width: 100%;
    flex: 1;
    max-width: 100%;
    max-height: 100%;
    padding: 15px;
    /* margin-bottom:50px; */
    /* overflow-y: auto;  */
  }

  .white-paper-card-right {
    width: 100%;
    max-height: 100vh;
    height: 550px;
    overflow-y: auto; /* Ensure content inside is scrollable */
    display: flex;
    justify-content: center;
    margin: 30px auto 30px auto;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
  }


  .white-paper-card-right {
    width: 100%;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
    overflow-y: auto; /* Ensure content inside is scrollable */
    display: flex;
    justify-content: center;
    margin: 30px auto 30px auto;
    border-radius: 10px;
  }

  .white-paper-card-right-text {
    /* border: 1px solid red; */
    align-items: unset;
    width: 100%;
    padding: 20px;
    /* height: 200px; */
    overflow-y: auto;
  }

  .white-paper-card-right-text button {
    margin-bottom: 10px;
  }
  
  .white-paper-card-right-text p {
    text-align: left;
  }

  .white-paper-card-left-item-1 {
    border: 0.5px solid transparent; /* Decrease border width here */
    padding: 4px; /* Adjust padding as necessary */
  }
  .white-paper-card-left-text-card-1{
    /* border: 1px solid red; */
    margin-left: 10px;
  }
  .white-style-title2{
    font-size: 28px;
  }
}