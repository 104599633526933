.grid-card{
    /* border: 1px solid red; */
    padding: 20px;
    text-align: center;
    margin-bottom: 20px;
}

.background-text{
    /* border: 1px solid red; */
    text-align: center;
    color: #2a2828;
    backdrop-filter: blur(10px);
    background-color: rgba(239, 232, 232, 0.5); 
    padding: 4px;
    width: 35%;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
}

.background-text >p{
    font-size: 12px;
    color: #000000;
    line-height: 126%;
    letter-spacing: 1px;
    font-family: "Poppins", sans-serif;
    font-weight: 300;
}
.grid-card-item-text-1{
    /* border: 1px solid blue; */
    text-align: left;
    margin: 10px 10px 10px 0;
}
.grid-card-item-text-2{
    /* border: 1px solid red; */
    text-align: left;
    margin: 10px 8px 0px 0;
}



/* flex-card */
.flex-card-container{
    /* border: 1px solid red; */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    padding: 10px;
    background-color: #1C1C1C;
}

.flex-card-1{
    /* border: 1px solid blue; */
    text-align: left;
    width: 100%;
    color: #C7C7C7;
    margin-bottom: 30%;
}
.flex-card-2{
    /* border: 1px solid yellow; */
    text-align: left;
    width: 100%;
    color: #FFFFFF;
}

.flex-card-1-paragraph{
        color: #C7C7C7;
        font-family: Poppins,sans-serif;
        font-size: 20px;
        /* font-weight: 300; */
        /* letter-spacing: -.8px; */
        /* line-height: 130%; */
        text-align: left;
    font-weight: 400;
    letter-spacing: -1px;
    line-height: 150%;
}

.flex-card-2-paragraph{
    color: #C7C7C7;
    font-family: Poppins,sans-serif;
    font-size: 20px;
    font-weight: 600;
    letter-spacing: -1px;
    line-height: 150%;
    text-align: left;
}


/* Responsive adjustments */
@media (max-width: 600px) {
    .flex-card-1{
        /* border: 1px solid blue; */
        width: 100%;
        padding: 10px;
    }
    .flex-card-2{
        /* border: 1px solid yellow; */
        width: 100%;
        padding: 10px;
    }
    .flex-card-1-paragraph{
    font-size: 16px;
        font-weight: 400;
        letter-spacing: 1px;
        line-height: 150%;
        text-align: left;
    }
    .flex-card-2-paragraph{
        font-size: 18px;
        font-weight: 600;
        letter-spacing: 0.7px;
        line-height: 150%;
        text-align: left;
    }
    .flex-card-container{
        /* border: 1px solid yellow; */
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 90%;
        padding: 10px;
        background-color: #1C1C1C;
        margin: auto;
    }
}