.nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 90%;
  margin: 0 auto;
  z-index: 2;
  position: relative;
  margin-top: 20px;
}
.navbar-hushl {
  display: flex;
  background-color: rgba(255, 255, 255, 1);
  backdrop-filter: blur(10px);
  position: fixed;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: 0 auto;
  padding: 0 5%;
  top: 0;
  z-index: 5;
  /* height: 70px; */
  transition: all 0.3s ease-in-out;
}
.navbar-hushl.hidden {
  top: -100px;
}
.navbar-links {
  display: flex;
  align-items: center;
  gap: 20px;
  /* padding: 25px 0; */
  justify-content: center;
  position: relative;
  position: relative;
}
.navbar-link-item {
  padding: 20px 0;
}
.hover-content-right-item-link {
  cursor: pointer;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 18px;
  color: #959595;
  line-height: 136%;
  letter-spacing: -0.5px;
  transition: all 0.3s;
  display: flex;
  gap: 5px;
}
.hover-content-right-item-link {
  margin-top: 10px;
}
.hover-content-right-item-link img {
  opacity: 0.4;
  width: 12px;
}
.navbar-logo {
  cursor: pointer;
}
.hover-content-right-item-link:hover {
  color: #000;
}
.navbar-links .hover-content {
  width: 100%;
  transition: all 0.3s ease-in-out;
  overflow: hidden;
  display: none;
  left: 0;
  position: fixed;

  padding-left: 5%;
  top: 60px;
  width: 100%;
  display: grid;
  grid-template-columns: 0.2fr 1fr;
  z-index: 5;
  color: #000;
  background-color: #fff;
  height: 0;
  border-radius: 10px;
  border-top: 1px solid rgba(231, 231, 231, 0.5);
}
/* .navbar-link-item:hover .hover-content {
  height: 500px;
} */
.navbar-links .show-hover-content {
  height: 500px;
  display: grid;

  box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.06);
}
.hover-content-right-featued {
  background-image: linear-gradient(170deg, #5382ed, #d36679, #d36679, #d36679);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.hover-content-right-featued h1 {
  /* font-size: 30px;
  font-weight: 500;
  line-height: 136%;
  letter-spacing: -2px;
  font-family: "Poppins", sans-serif;
  color: #fff;
  text-align: left;
  line-height: 140%; */
  padding: 0 7%;
  text-align: center;
}
.hover-content-right-featued .source {
  display: flex;
  text-decoration: underline;
  align-items: center;
  gap: 10px;
  margin-top: 20px;
  font-size: 18px;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  color: #fff;
  line-height: 136%;
  letter-spacing: -0.5px;
}
.hover-content-right-featued .source img {
  width: 30px;
}
.hover-content-right {
  margin-left: 10%;
}
.hover-content-right-items {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}
.hover-content-right-item h1 {
  /* font-size: 28px;
  font-weight: 500;
  line-height: 136%;
  letter-spacing: -2px;
  font-family: "Poppins", sans-serif;
  color: #000;
  line-height: 110%; */
}
.hover-content-right-item p {
  /* font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 18px;
  color: #969696;
  line-height: 136%;
  letter-spacing: -0.5px; */
  margin-top: 4px;
  margin-bottom: 10px;
}
.hover-content-right-item .cta {
  font-size: 20px;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  cursor: pointer;
  background: linear-gradient(90deg, #5382ed, #d36679, #d36679);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  transition: all 0.3s;
  display: flex;
  align-items: center;
  gap: 5px;
}
.hover-content-right-item .cta img {
  width: 14px;
}
.hover-content-right {
  display: grid;
  grid-template-columns: 1fr 0.6fr;
}
.navbar-links .hover-content > div {
  height: 100%;
  /* padding-top: 60px; */
}
.hover-content-left {
  height: 100%;
  border-right: 1.2px solid #e7e7e7;
}
.navbar-links > div {
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;
  /* font-family: "Poppins", sans-serif; */
  /* font-weight: 500;
  font-size: 16px;
  letter-spacing: -0.5px; */
}
.hover-content-left h1 {
  /* font-size: 32px;
  font-weight: 500;
  line-height: 136%;
  letter-spacing: -2px;
  font-family: "Poppins", sans-serif;
  color: #000;
  line-height: 110%; */
}
.hover-content-left p {
  /* font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 18px;
  color: #969696;
  line-height: 136%;
  letter-spacing: -0.5px; */
  margin-top: 3px;
  margin-bottom: 10px;
}
.hover-content-left .cta {
  background-image: linear-gradient(90deg, #5382ed, #d36679, #d36679);
  -webkit-background-clip: text;
  background-clip: text;

  -webkit-text-fill-color: transparent;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 20px;
  cursor: pointer;
  transition: all 0.3s;
}
.nabar-cta button {
  background-color: #000;
  color: #fff;
  padding: 10px 30px;
  border: none;
  border-radius: 30px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 18px;
  cursor: pointer;
  transition: all 0.3s;
}
.menu-items {
  display: flex;
  align-items: center;
  gap: 20px;
  justify-content: center;
}
.nav-mobile {
  display: none;
}
.get-start {
  background-color: black;
  padding: 10px 20px;
  border-radius: 5px;
}
.outer-get-start {
  width: 25%;
  display: flex;
  justify-content: flex-end;
}
.hushl-menubar {
  width: 100%;
  padding-bottom: 60px;
  position: absolute;
  top: 0;
  height: 100vh;
  left: 0;
  background-color: #fff;
  overflow-x: scroll;
  z-index: 10;
  box-shadow: 0px 1px 15px rgba(0, 0, 0, 0.06);
}
.hushl-menu-bar-list {
  width: 90%;
  margin: 0 auto;
  margin-top: 20px;
}
.hushl-menu-bar-item-title {
  width: 100%;
  justify-content: space-between;
  display: flex;
}
.hushl-menu-bar-item-title img {
  width: 14px;
}
.hushl-menu-bar-item-title h1 {
  font-size: 22px;
  font-weight: 500;
  line-height: 136%;
  letter-spacing: -1px;
  font-family: "Poppins", sans-serif;
  color: #2a2a2a;
  line-height: 110%;
}
.hushl-menu-bar-item {
  margin-bottom: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid #f5f5f5;
}
.hushl-menu-bar-item > p {
  font-size: 14px;
  margin-top: 5px;
  font-weight: 500;
  line-height: 136%;
  letter-spacing: -0.5px;
  font-family: "Poppins", sans-serif;
  color: #b8b8b8;
}
.menubar-item-wrap p {
  font-size: 14px;
  margin-top: 5px;
  font-weight: 500;
  line-height: 136%;
  letter-spacing: -0.5px;
  font-family: "Poppins", sans-serif;
  color: #b8b8b8;
}
.hushl-menu-bar-item-title img {
  transform: rotate(-90deg);
}
.hushl-menu-bar-item-title.active img {
  transform: rotate(0);
}
.menu-navbar img {
  cursor: pointer;
}
.hushl-menubar-header > img {
  cursor: pointer;
}
.hushl-menubar-header {
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  margin: 0 auto;
}
.hover-content-right-items-menubar {
  display: flex;
  flex-direction: column;
}
.hover-content-right-items-menubar .hover-content-right-item h1 {
  letter-spacing: -0.6px;
  font-size: 16px;
}
.hover-content-right-items-menubar .hover-content-right-item-link {
  font-size: 14px;
}
.hover-content-right-items-menubar .hover-content-right-item-link img {
  width: 10px;
}
.hover-content-right-items-menubar .hover-content-right-item p {
  font-size: 12px;
  margin-top: 5px;
  font-weight: 500;
  line-height: 136%;
  letter-spacing: -0.5px;
  font-family: "Poppins", sans-serif;
  color: #b8b8b8;
  margin-bottom: 0;
}

.hover-content-right-items-menubar .hover-content-right-item .cta {
  font-size: 15px;
  letter-spacing: -0.5px;
  margin-top: 0px;
}
.hover-content-right-items-menubar .hover-content-right-item .cta img {
  width: 10px;
}
@media (max-width: 800px) {
  .nav-desktop {
    display: none;
  }
  .nav-mobile {
    z-index: 4;
    margin: 0;
    display: flex;
    position: fixed;
    background-color: rgba(255, 255, 255, 0.4);
    backdrop-filter: blur(10px);
    width: 100%;
    padding: 20px 10px;
  }
  .logo > img {
    width: 90px;
  }
}
.menu-icon img {
  cursor: pointer;
}
.menu-icon {
  position: relative;
}
.menu-modal {
  position: absolute;
  padding: 20px;
  padding-top: 100px;
  width: 100%;
  height: 100vh;
  top: 0;
  z-index: 3;

  background-color: #fff;

  border-radius: 10px;
  box-shadow: 0px 1px 15px rgba(0, 0, 0, 0.06);
}
.menu-modal-item {
  margin-bottom: 20px;
  font-family: "SF Pro Display", sans-serif;
  font-weight: 500;
  font-size: 24px;
  color: #909090;
  line-height: 136%;
  letter-spacing: -1px;
}
.hover-content-left {
  /* padding-left: 40px; */
  padding-right: 10px;
  padding-top: 60px;
}
.hover-content-right-items {
  padding-top: 60px;
  padding-right: 10px;
}
.hover-content-right-items-menubar {
  padding: 0;
  padding-top: 20px;
  padding-left: 30px;
  border-left: 1px solid #e7e7e7;
  margin-top: 10px;
}
.hover-content-right-item .hover-width {
  transition: width 0.2s ease-in-out;
  height: 1.2px;
  width: 0px;
  background: linear-gradient(90deg, #5382ed, #d36679, #d36679);
}
.hover-content-right-item span {
  background: linear-gradient(90deg, #5382ed, #d36679, #d36679);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
.hover-content-right-item:hover .hover-width {
  width: 100px;
}
.menu-navbar {
  display: none;
}
@media (max-width: 800px) {
  .navbar-links {
    display: none;
  }
  .menu-navbar {
    display: block;
  }
  .navbar-hushl .nabar-cta {
    display: none;
  }
  .nabar-cta button {
    font-size: 13px;
    letter-spacing: -1px;
    padding: 6px 20px;
  }
  .navbar-hushl {
    z-index: 5;
    height: 60px;
  }
  .navbar-logo img {
    width: 90px;
  }
}
