.landing-section {
  text-align: center;
  position: relative;
  min-height: 100vh;
}
.bg-landing {
  width: 90%;
  margin: 0 auto;
  margin-top: -250px;
}
.bg-landing img {
  width: 100%;
}

.landing-text {
  position: absolute;
  top: 50%;
  width: 100%;
  z-index: 2;
}
.landing-text h1 {
  /* font-size: 84px;
  line-height: 136%;
  letter-spacing: -5px;
  font-family: "Poppins", sans-serif;

  font-weight: 500; */
}
.landing-text p {
  max-width: 700px;
  margin: 0 auto;
  opacity: 0.9;
  /* font-size: 20px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  color: #b5b5b5;
  line-height: 136%;
  letter-spacing: -1px; */
  margin-top: 20px;
}
.landing-button {
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: center;
  margin-top: 20px;
}
.landing-button .secondary {
  background-color: transparent;
  color: #000;
  border: 1.2px solid #000;
}
.landing-text h1 span {
  color: #ababab;
}
.home-nav-item {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}
.home-navogation {
  width: 100%;
  position: absolute;
  top: 66%;
  margin: 0 auto;
}
.home-navogation-inner {
  width: 80%;
  margin: 0 auto;
  margin-top: 260px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.home-nav-item {
  cursor: pointer;
}
.home-nav-item p {
  /* font-family: "Poppins", sans-serif;
  font-weight: 500;
  color: #000;
  font-size: 24px;
  line-height: 136%;
  text-align: left;
  letter-spacing: -1px; */
}
.home-nav-item p span {
  color: #ababab;
}

.second-section-flex {
  display: grid;
  grid-template-columns: 1fr 0.5fr;
  gap: 20px;
}
.second-section {
  width: 90%;
  margin: 0 auto;
  margin-top: 100px;
}

.second-section-flex h2 {
  /* font-size: 48px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  color: #adadad;
  line-height: 136%;
  letter-spacing: -2px; */
}

.second-section-flex h2 p {
  /* color: #000; */
}
.second-section-flex h2 p span {
  /* background-image: linear-gradient(90deg, #5382ed, #ac6eb3, #d36679);
  -webkit-background-clip: text;
  background-clip: text;

  -webkit-text-fill-color: transparent; */
}
.second-section-flex > p {
  /* font-size: 18px;
  font-family: "Poppins", sans-serif;
  letter-spacing: -1px;
  color: #9c9c9c;
  font-weight: 500; */
}

.second-section-flex > p span {
  color: #000;
}
.second-section-items {
  margin-top: 100px;
  margin-bottom: 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.second-section-items > div {
  width: 23%;
  background-color: #ffffff;
  padding: 35px 20px;
  min-height: 150px;
  /* shadow */
  border-radius: 15px;
  box-shadow: 0px 1px 15px rgba(0, 0, 0, 0.06);
}

.second-section-items > div h1 {
  /* font-size: 32px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  color: #000;
  line-height: 136%;
  letter-spacing: -1px; */
}

.second-section-items > div p {
  /* color: #bababa;
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  line-height: 136%;
  letter-spacing: -0.2px; */
  margin-top: 5px;
}
.third-section {
  width: 90%;
  margin: 0 auto;
  margin-bottom: 90px;
  margin-top: 180px;
}
.third-section h2 {
  /* font-size: 48px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  color: #adadad;
  line-height: 136%;
  letter-spacing: -2px; */
}
.third-section-head {
  text-align: center;
  margin-top: 60px;
  display: block;
}
.new-section {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 140px;
  align-items: center;
}
.new-section-head {
  font-size: 24px;
  font-family: "SF Pro Display", sans-serif;
  font-weight: 300;
  color: #adadad;
  line-height: 136%;
  letter-spacing: -1px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.new-section-main {
  width: 90%;
  padding-top: 40px;

  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.margin-ttile {
  /* margin-top: 190px; */
}
.black-span {
  color: #000;
}
.grad-span {
  background: linear-gradient(90deg, #5382ed, #ac6eb3, #d36679);
  -webkit-background-clip: text;
  background-clip: text;

  -webkit-text-fill-color: transparent;
}
.home-team-cards {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.home-team-cards {
  margin-top: 60px;
}
.home-team-card .social {
  justify-content: flex-start;
  opacity: 0.5;
}
.home-team-cards > div {
  padding: 20px;
  width: 30%;
  background-color: #fff;
  border-radius: 15px;
  /* padding-bottom: 20px; */
  box-shadow: 0px 1px 15px rgba(0, 0, 0, 0.06);
}
.home-team-card-img {
  width: 100%;
  margin: 0 auto;
  border-radius: 15px;
  overflow: hidden;
  position: relative;
}

.home-team-card-img img {
  width: 100%;
  object-fit: cover;
}
.home-team-card-img p {
  position: absolute;
  z-index: 2;
  bottom: 10px;
  color: #fff;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  line-height: 120%;
  letter-spacing: -1px;
  right: 10px;
  font-size: 36px;
}

.home-team-card h1 {
  /* font-size: 30px;
  letter-spacing: -1.4px;
  font-family: "Poppins", sans-serif;
  font-weight: 500; */
  margin-top: 20px;
  margin-bottom: 10px;
}
.home-team-card h1 span {
  color: #adadad;
}

.home-team-card > p {
  /* font-size: 17px;
  color: #adadad;
  line-height: 136%;
  font-weight: 500;
  font-family: "Poppins", sans-serif; */
}
.home-team-card > p {
  /* font-size: 17px;
  color: #adadad;
  line-height: 136%;
  font-weight: 500;
  letter-spacing: -1px;
  
  font-family: "Poppins", sans-serif; */
  padding-right: 10px;
}
.home-team-card-img {
  height: 360px;
}
.home-team-card-img img {
  height: 100%;
}
.menu-items p {
  cursor: pointer;
  font-family: "SF Pro Display", sans-serif;
  font-weight: 500;
  color: #626262;
  font-size: 16px;
  transition: all 0.3s ease;
  letter-spacing: -0.4px;
}
.menu-items p:hover {
  color: #000;
}
.menu-items {
  gap: 30px;
}
.our-works {
  width: 100%;
  display: flex;
  align-items: flex-start;
  margin-top: 30px;
  gap: 2rem;
}
.our-works > div {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 28px;
  position: relative;
}
.our-works > div > div {
  position: relative;
  cursor: pointer;
}
.our-works-left-item-img {
  width: 100%;
}
.our-works-left-item-img img {
  width: 100%;
}
.hover-content {
  position: absolute;
  bottom: 40px;
  color: #fff;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  /* padding: 0 20px; */
  gap: 20px;
}
.hover-content-right-item:hover .hover-content h3 {
  font-size: 24px;
  font-family: "SF Pro Display", sans-serif;
  font-weight: 500;
  letter-spacing: -1px;
  opacity: 0.6;
}
.hover-content p {
  display: flex;
  align-items: center;
  gap: 10px;
}
.hover-content p img {
  width: 40px;
  display: flex;
}
.hover-content p span {
  font-size: 18px;
  font-family: "SF Pro Display", sans-serif;
  font-weight: 500;
  letter-spacing: -1px;
}
.layer {
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  display: none;

  width: 100%;
  height: 0;
  transition: all 0.5s ease;
  top: 0;
}
.about-container {
  background-color: #fcfcfc;
  border: 1px solid #dfdfdf;
}
.our-works > div > div:hover .hover-content {
  display: flex;
}
.our-works > div > div:hover .our-works-left-item-text {
  display: none;
}
.our-works > div > div:hover .layer {
  display: block;
  height: 100%;
}
.our-works-left-item-text {
  position: absolute;
  color: #fff;
  bottom: 40px;
  padding: 0 20px;
  width: 100%;
}
.our-works-left-item-text h1 {
  font-size: 42px;
  font-family: "SF Pro Display", sans-serif;
  font-weight: 500;
  letter-spacing: -1px;
}
.our-works-left-item-text p {
  font-size: 18px;
  font-family: "SF Pro Display", sans-serif;
  font-weight: 500;

  opacity: 0.8;
}
.about-text h1 {
  font-size: 58px;
}
.about-text p {
  font-size: 18px;
}
.team-section {
  width: 100%;
  position: relative;
}
.team-section img {
  width: 100%;
  display: flex;
}
.team-section p {
  position: absolute;
  width: 50%;
  bottom: 60px;
  left: 60px;
  /* font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 32px;
  color: #fff;
  line-height: 136%;
  letter-spacing: -0.5px; */
  margin-top: 10px;
}
.about-us-team {
  width: 90%;
  margin: 0 auto;
  margin-top: 120px;
  margin-bottom: 120px;
}
.about-team-cards {
  margin-top: 90px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.about-team-card {
  display: flex;
  align-items: flex-start;
  gap: 20px;
}
.about-team-content {
  margin-top: 20px;
}
.about-team-content h1 {
  /* font-size: 30px;
  font-weight: 500;
  line-height: 136%;
  letter-spacing: -2px;
  font-family: "Poppins", sans-serif;
  color: #000;
  text-align: left;
  line-height: 120%; */
}
.about-team-content p {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 20px;
  color: #a1a1a1;
  line-height: 136%;
  letter-spacing: -0.5px;
  /* margin-top: 10px; */
}
.about-team-card-img {
  width: 130px;
  height: 130px;
  border-radius: 50%;
  display: flex;
  align-items: center;

  height: 130px;
  overflow: hidden;
}
.about-team-card-img img {
  width: 140%;
  position: relative;
  left: -10px;
  object-fit: cover;
}
.our-journey {
  width: 90%;
  margin: 0 auto;
  margin-top: 100px;
}
.our-journey .impact-second-section-top h1,
.about-us-team .impact-second-section-top h1 {
  /* font-size: 48px; */
}
.our-journey {
  margin-top: 40px;
}
.our-journey h2 {
  font-size: 34px;
  font-weight: 500;

  line-height: 136%;
  letter-spacing: -2px;
  font-family: "Poppins", sans-serif;
  color: #000;
  text-align: left;
  line-height: 120%;
}
.our-journey h2 span {
  color: #9c9c9c;
}
.about-bg {
  margin-top: -280px;
}
.about-navigation .home-navogation-inner {
  width: 100%;
  transform: scale(0.9);
}
.about-text {
  top: 50%;
}
.clients {
  width: 90%;
  margin: 0 auto;
  margin-bottom: 50px;
}
.home-clients {
  margin-top: 180px;
}
.client-text h1 {
  /* font-size: 32px; */
}
.clients .client-card {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  margin-top: 50px;
}
.client-text h1 {
  /* font-size: 30px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  color: #a0a0a0;
  line-height: 136%;
  letter-spacing: -1.5px; */
  /* background-image: linear-gradient(90deg, #5382ed, #ac6eb3, #d36679);
  -webkit-background-clip: text;
  background-clip: text;

  -webkit-text-fill-color: transparent; */
}

.client-text .home-nav-item {
  justify-content: flex-start;
  margin-top: 20px;
}
.client-text > span {
  color: #0b0b0b;
  /* font-size: 24px;
  font-weight: 500;
  display: block;
  font-family: "Poppins", sans-serif;
  line-height: 136%;
  letter-spacing: -1px; */
  margin-top: 24px;
}
.cleint-img {
  width: 400px;
  position: relative;
}
.cleint-name {
  position: absolute;
  bottom: 30px;
  right: 20px;
  text-align: left;
  color: #fff;
  font-size: 28px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  line-height: 116%;
  letter-spacing: -1px;
}
.cleint-name span {
  color: #bdbdbd;
}
.client-text {
  width: 60%;
}
.home-clients .client-text {
  width: 50%;
}
.cleint-img img {
  width: 100%;
  border-radius: 20px;
}
.clients .client-card:nth-child(even) {
  flex-direction: row-reverse;
}

.home-clients .cleint-img {
  width: 40%;
}
.home-clients .david-img {
  width: 400px;
}
.home-clients .david-img img {
  height: 400px;
  object-fit: cover;
}
.about-section {
  height: 1200px;
  min-height: 100vh;
}

.card-1 {
  margin-top: 160px;
}
.card-2 {
  margin-top: 70px;
}
.card-6 {
  display: none;
}
.card-5 {
  margin-top: 50px;
}
.card-4 {
  margin-top: -40px;
}

@media (max-width: 1300px) {
  .second-section-flex h2 {
    /* font-size: 36px; */
  }
  .second-section-flex > p {
    /* font-size: 14px; */
    /* opacity: 0.6; */
  }
  .new-section {
    margin-top: 7px;
  }
  .second-section-items > div h1 {
    font-size: 22px;
  }
  .second-section-items > div p {
    font-size: 16px;
    letter-spacing: -1px;
  }
  .fourth-section h2 {
    font-size: 36px;
  }
  .home-team-cards {
    flex-wrap: wrap;
  }
  .home-team-cards > div {
    width: 48%;
  }
  .landing-text h1 {
    font-size: 64px;
  }
  .bg-landing {
    margin-top: -130px;
  }
  .home-team-card-img p {
    font-size: 24px;
  }
  .home-team-cards {
    margin-top: 20px;
  }
  .home-team-card h1 {
    font-size: 28px;
  }
  .home-nav-item p {
    /* font-size: 20px; */
  }
  .home-team-cards > div {
    margin-top: 20px;
  }
}
@media (max-width: 1100px) {
  .about-team-cards {
    display: grid;
    gap: 20px;
    grid-template-columns: repeat(2, 1fr);
  }
  .about-team-card-img {
    width: 100px;
    height: 100px;
  }
  .about-team-card-img img {
    width: 140px;
  }
  .impact-second-section-top > div {
    width: 100%;
  }
  .impact-second-section-top {
    flex-direction: column;
    align-items: flex-start;
  }
  .team-section p {
    font-size: 24px;
    width: 90%;
  }
  .our-works {
    flex-direction: column;
  }
  .our-works-left-item-text p {
    font-size: 16px;
  }
  .our-works-left-item-text h1 {
    font-size: 32px;
  }
}
@media (max-width: 800px) {
  .about-team-cards {
    margin-top: 40px;
  }
  .about-navigation .home-nav-item:nth-child(even) {
    /* justify-content: flex-end; */
    position: relative;
    left: 40px;
  }
  .about-navigation {
    overflow: hidden;
  }
  .about-navigation .home-nav-item {
    align-items: center;
  }
  .about-text br {
    display: none;
  }
  .clients .client-card:nth-child(even) {
    flex-direction: column-reverse;
  }
  .home-clients .david-img {
    width: 100%;
  }
  .home-clients .cleint-img {
    width: 100%;
  }
  .home-clients .client-text {
    /* width: 350px; */
    width: 100%;
  }
  .our-journey .impact-second-section-top h1,
  .about-us-team .impact-second-section-top h1 {
    /* font-size: 36px; */
  }
  .second-section {
    margin-top: 190px;
  }
  .home-team-cards > div {
    width: 100%;
  }
  .second-section-items {
    flex-wrap: wrap;
  }
  .second-section-items > div {
    width: 48%;
    margin-bottom: 20px;
  }
  .landing-text p {
    font-size: 13px;
    letter-spacing: -0.5px;
    padding: 0 5px;
    margin-top: 2px;
    margin-bottom: 8px;
  }
  .third-section {
    margin-top: 100px;
  }
  .second-section-flex {
    display: flex;
    flex-direction: column;
  }
  .landing-text h1 {
    font-size: 31px;
    letter-spacing: -2px;
  }

  .home-nav-item p {
    /* font-size: 16px; */
  }
  .bg-landing {
    margin-top: 100px;
  }
  .home-nav-item img {
    width: 15px;
  }
  .landing-section {
    min-height: unset;
    height: 450px;
  }
  .about-section {
    min-height: 100vh;
    height: 700px;
  }
  .about-navogation {
    top: 72%;
  }
  .home-navogation-inner {
    width: 90%;
  }
  .home-nav-item {
    gap: 10px;
  }
  .second-section-items {
    margin-top: 30px;
    margin-bottom: 0;
  }
  .fourth-section {
    margin-top: 100px;
  }
  .fourth-section h2 {
    font-size: 26px;
  }
  .home-team-card > p {
    /* font-size: 16px; */
  }
  .home-team-card h1 {
    font-size: 24px;
  }
  .home-team-cards > div {
    padding: 10px;
    padding-bottom: 30px;
  }
  .fourth-section h2 br {
    display: none;
  }
  .second-section-items > div h1 {
    font-size: 20px;
  }
  .second-section-items > div p {
    font-size: 14px;
    opacity: 0.8;
  }
  .team-section p {
    font-size: 22px;
    left: 20px;
  }
  .about-team-card-img img {
    width: 120px;
  }

  .about-team-card-img {
    width: 80px;
    height: 80px;
  }
  .home-navogation-inner {
    margin-top: 110px;
  }
  .home-navogation {
    top: 75%;
  }
  .bg-landing {
    width: 100%;
    overflow: hidden;
  }
  .bg-landing img {
    transform: scale(1.3);
  }
  .second-section-flex h2,
  .third-section h2 {
    /* font-size: 26px; */
  }
  .second-section-flex h2 {
    /* padding: 0 10px; */
    /* font-size: 24px; */
  }
  .second-section-items > div {
    padding: 25px 15px;
    min-height: 130px;
  }
  .fourth-section {
    margin-top: 40px;
  }
  .home-team-card-img {
    height: 260px;
  }

  .landing-text {
    top: 50%;
  }
  .home-navogation {
    top: 85%;
  }
}
.card-1 {
  margin-top: 160px;
}
.card-2 {
  margin-top: 70px;
}
.card-6 {
  display: none;
}
.card-5 {
  margin-top: 50px;
}
.card-4 {
  margin-top: -40px;
}

@media (max-width: 600px) {
  .about-text {
    top: 30%;
  }
  .about-navigation {
    top: 53%;
    margin-top: 10px;
  }
  .about-navigation .home-navogation-inner {
    display: flex;
    flex-wrap: wrap;
  }
  .team-section p {
    font-size: 16px;
  }

  .about-section {
    min-height: unset;
  }
  .about-section {
    height: 600px;
  }
  .about-navigation .home-nav-item {
    width: 44%;
    /* align-items: flex-start; */
    text-align: left;
    justify-content: flex-start;
    margin-bottom: 15px;
  }
  .about-team-cards {
    grid-template-columns: repeat(1, 1fr);
  }
  .about-team-content h1 {
    /* font-size: 20px; */
  }
  .card-1 {
    margin-top: 40px;
  }
  .card-2 {
    margin-top: 40px;
  }
  .card-6 {
    display: flex;
    margin-top: 40px;
  }
  .card-5 {
    display: none;
  }
  .card-4 {
    margin-top: 40px;
  }
}
