.last-flex-card{
    /* border: 1px solid red;  */
    background-color: #F5F5F5;
    max-width: 1300px;
    margin:auto;
    margin-top: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 60px;
  text-align: left;
  padding: 50px;
}

/* .text-card-blur-text{
    text-align: left;
    color: #999999;
    backdrop-filter: blur(10px);
    background-color: rgba(239, 232, 232, 0.5);
    padding: 4px;
    width: 20%;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
}

.text-card-blur-text>p {
    font-size: 12px;
    color: #000000;
    line-height: 126%;
    letter-spacing: 1px;
    font-family: "Poppins", sans-serif;
    font-weight: 300;
} */
.last-flex-left{
  /* border: 1px solid red; */
    overflow: hidden;
    border-radius: 15px;
}

.last-flex-right {
  /* border: 1px solid red; */
    width: 40%;
  }

  .last-buttons{
    font-size: 14px;
    padding: 8px 20px ;
    background-color: #0040FF;
    color: #FFFFFF;
    cursor: pointer;
    margin-top: 10px;
    border: none;
  }

  /* Responsive adjustments */
@media (max-width: 600px) {
  .last-flex-card{
    /* border: 1px solid red;  */
   flex-direction: column;
   width: 100%;
}
.last-flex-right {
  width: 100%;
}

.text-card-blur-text{
  /* border: 1px solid red; */
  width: 40%;
}
.last-flex-left{
  width:100%;
}
.last-flex-left iframe {
  width: 100%;
  height: 220px;
}
}