.style-title1 {
  font-size: 74px;

  line-height: 136%;
  letter-spacing: -4px;
  font-family: "Poppins", sans-serif;

  font-weight: 500;
}
.gradient-text {
  background-image: linear-gradient(90deg, #5382ed, #ac6eb3, #d36679);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
.style-title1 span {
  color: #ababab;
}
.style-title2 {
  font-size: 46px;

  line-height: 136%;
  letter-spacing: -2px;
  font-family: "Poppins", sans-serif;

  font-weight: 500;
}
.style-title2 span {
  color: #ababab;
}

.style-subheader {
  font-size: 32px;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  color: #000;
  line-height: 136%;
  letter-spacing: -1px;
}
.style-nav-header {
  font-size: 28px;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  color: #000;
  line-height: 136%;
  letter-spacing: -1px;
}
.style-subtitle-landing {
  font-size: 23px;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  color: #ababab;
  line-height: 136%;
  letter-spacing: -0.5px;
}
.style-subtitle1 {
  font-size: 20px;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  color: #ababab;
  line-height: 136%;
  letter-spacing: -0.5px;
}
.style-subtitle2 {
  font-size: 18px;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  color: #ababab;
  line-height: 136%;
  letter-spacing: -0.5px;
}
.style-subtitle3 {
  font-size: 16px;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  color: #ababab;
  line-height: 136%;
  letter-spacing: -0.5px;
}
.cta-type1-text {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  color: #000;
  font-size: 20px;
  line-height: 136%;
  text-align: left;
  letter-spacing: -1px;
}

.cta-type1-text-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  color: #000;
  font-size: 24px;
  line-height: 136%;
  text-align: left;
  letter-spacing: -1px;
  font-style: italic;
}

.blur-text{
  /* border: 1px solid red; */
  text-align: left;
  color: #D9D9D9;
  backdrop-filter: blur(10px);
  background-color: rgba(239, 232, 232, 0.5);
  padding: 4px;
  width: fit-content;
  align-items: center;
  justify-content: center;
  margin-bottom: 2px;
  border-radius: 4px;
}
.blur-text>p{
  font-size: 12px;
  color: #000000;
  line-height: 126%;
  letter-spacing: 1px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
}

/* overide the default styles of the components. */
.second-section-flex h2 {
  color: #ababab;
}
.style-title2 p {
  color: #000;
}
.client-text h1 {
  color: #ababab;
  /* font-size: 30px; */
}
.client-text span {
  color: #000;
  display: block;
}
.navbar-link-item {
  color: #000;
}
.footer-bottom-item h1 {
  color: #000;
}

.footer-top-left p {
  color: #fff;
}
.footer-top-right p {
  color: #fff;
}
.footer-top-right p span {
  opacity: 0.6;
}
.third-section h2 {
  color: #ababab;
}
.third-section h2 .black-span {
  color: #000;
}
.hover-content-right-featued h1 {
  color: #fff;
}
.internal-grad-section h1 {
  color: #fff;
}
.product-card-desc p {
  color: #000;
  opacity: 0.7;
}
.team-section p {
  color: #fff;
}
.about-us-navigation h1 {
  color: #ababab;
}
.about-us-navigation h1 span {
  color: #000;
}
.people-display-card-text > p {
  color: #ababab;
}
.careers-page-header h1 span {
  font-size: 32px;
}
.hcai-content p {
  color: #fff;
}
.hcai-flex-item p {
  color: #000;
  opacity: 0.7;
}
.contact-header {
  color: #ababab;
}

@media (max-width: 800px) {
  .style-subheader {
    font-size: 20px;
    letter-spacing: -0.7px;
  }
  .style-title2 {
    font-size: 22px;
    letter-spacing: -1px;
  }
  .style-subtitle2 {
    font-size: 14px;
  }
  .style-subtitle-landing {
    font-size: 14px;
  }
  .second-section-flex > p {
    opacity: 0.8;
  }
  .style-subtitle3 {
    font-size: 14px;
  }
  .style-subtitle2 {
    font-size: 14px;
  }
  .cta-type1-text {
    font-size: 16px;
  }
  .style-title1 {
    font-size: 32px;
    letter-spacing: -2px;
  }
  .style-subtitle1 {
    font-size: 16px;
  }
  .impact-navigation-items .cta-type1-text {
    font-size: 15px;
  }
  .careers-page-header h1 span {
    font-size: 18px;
  }
  /* .impact-navigation-item br{} */
}
