.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
.modal-wrap {
  position: relative;
  background: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  max-width: 700px;
  height: 700px;

  width: 100%;
  border-radius: 8px;
}
.modal-content {
  padding: 41px 33px;
  overflow-y: scroll;
  height: 100%;
  width: 95%;
  text-align: left;
}
.modal-wrap > img {
  position: absolute;
  right: 20px;
  top: 20px;
  width: 35px;
}
.modal-content a {
  position: absolute;
  text-align: center;
  text-decoration: none;
  width: 91%;

  background-color: #fff;
  bottom: 20px;
  border: 1.5px solid #000;
  padding: 10px 0;
  margin-top: 20px;
  font-family: "SF Pro Display", sans-serif;
  font-weight: 500;
  font-size: 16px;
  color: #000;
  cursor: pointer;
}

.thesis-page {
  background-color: #f5f5f7;
}
.thesis-page {
  width: 90%;
  padding-top: 100px;
  margin: 0 auto;
}
.thesis {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 70px;
  flex-wrap: wrap;
}
.thesis-item {
  width: 100%;
  background-color: #fff;
  padding: 41px 33px;
  margin-bottom: 30px;
}
.thesis-item h3,
.modal-content h3 {
  font-size: 34px;
  font-family: "SF Pro Display", sans-serif;
  font-weight: 500;
  color: #000;
  line-height: 136%;
  letter-spacing: -1px;
}
.thesis-item p,
.modal-content p {
  color: #888888;
  font-size: 18px;
  font-family: "SF Pro Display", sans-serif;
  font-weight: 500;
  opacity: 0.8;
  margin-top: 14px;
}
.thesis-item button {
  width: 100%;
  background-color: transparent;
  border: 1.5px solid #000;
  padding: 10px 0;
  margin-top: 20px;
  font-family: "SF Pro Display", sans-serif;
  font-weight: 500;
  font-size: 16px;
  color: #000;
  cursor: pointer;
}
.thesis-flex {
  display: flex;
  margin-top: 50px;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
}
.thesis-flex > div {
  width: 49%;
}
@media (max-width: 800px) {
  .thesis > div {
    width: 100%;
    margin-bottom: 25px;
  }
  .thesis-item h3,
  .modal-content h3 {
    font-size: 24px;
  }
  .thesis-flex > div{
    width: 100%;
  }
  .thesis-flex {
    flex-direction: column;
  }
  .thesis-item p,
  .modal-content p {
    font-size: 14px;
  }
  .thesis {
    margin-top: 40px;
  }
  .thesis > div {
    padding: 20px;
  }
  .thesis-item button {
    padding: 8px 0;
    font-size: 14px;
  }
  .thesis-item button {
    border: 1px solid rgba(0, 0, 0, 0.6);
  }
  .modal-wrap {
    width: 90%;
    height: 500px;
    max-width: unset;
  }
  .modal-content a {
    width: 87%;
  }
  .modal-content {
    padding: 20px;
  }
}
