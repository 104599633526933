.footer {
  width: 90%;
  margin: 0 auto;
  margin-top: 140px;
}
.footer-logos{
  background: linear-gradient(90deg, #5382ed, #d36679, #d36679);
}
.footer-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.get-started {
  display: flex;
  cursor: pointer;
  gap: 10px;
}
.get-started p {
  color: #000000;
  gap: 10px;
  font-size: 20px;
  font-weight: 500;
  font-family: "SF Pro Display", sans-serif;
  font-weight: 500;
  line-height: 136%;
}
.get-started p span {
  color: #ababab;
}
.logo {
  width: 400px;
}
.logo p {
  color: #868686;
  font-size: 18px;
  margin-top: 20px;
  font-family: "SF Pro Display", sans-serif;
  font-weight: 500;
}
.social {
  display: flex;
  gap: 20px;
  align-items: center;
  margin-top: 20px;
}
.social img {
  cursor: pointer;
  opacity: 0.6;
  transition: all 0.3s ease;
}
.social img:hover {
  opacity: 1;
}
.footer-flex {
  padding-bottom: 50px;
  border-bottom: 1px solid #dddddd;
}

.footer-items {
  margin-top: 40px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding-bottom: 40px;
}
.footer-item p {
  color: #949494;
  font-size: 16px;
  font-family: "SF Pro Display", sans-serif;
  font-weight: 500;
  margin-top: 10px;
  cursor: pointer;
}
.footer-item p:nth-child(1) {
  font-size: 20px;
  cursor: default;
  color: #000000;
  letter-spacing: -0.4px;
}
.footer-hushl {
  width: 100%;
}
.footer-top {
  background: linear-gradient(90deg, #5382ed, #d36679, #d36679);
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fff;
  padding: 50px 5%;
}
.footer-top-left p {
  opacity: 0.6;
  margin-top: 15px;
  width: 400px;
  /* font-size: 18px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  line-height: 136%;
  letter-spacing: -0.4px; */
}
.footer-top-right {
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
}
.footer-top-right p {
  /* font-size: 22px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  justify-content: center;
  line-height: 136%;
  letter-spacing: -0.4px; */
}
.footer-bottom {
  padding: 0 5%;
  margin-top: 40px;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 50px;
  margin-bottom: 40px;
}
.footer-bottom-item {
  margin-bottom: 20px;
}
.footer-bottom-item h1 {
  /* font-size: 22px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  color: #000000; */
  margin-bottom: 10px;
}
.footer-bottom-item p {
  /* font-size: 16px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  color: #949494;
  cursor: pointer; */
  margin-top: 6px;
}
@media (max-width: 1100px) {
  .footer-bottom {
    grid-template-columns: repeat(3, 1fr);
    gap: 30px;
  }
  .footer-bottom-item h1 {
    font-size: 20px;
  }
  .footer-bottom-item p {
    font-size: 15px;
  }
}
@media (max-width: 800px) {
  
  .footer-top {
    flex-direction: column;
    align-items: flex-start;
    gap: 40px;
  }
  .footer-bottom-item h1 {
    font-size: 15px;
    letter-spacing: -1px;
  }
  .footer-top-left p {
    font-size: 15px;
    margin-top: 4px;
  }
  .footer-bottom {
    gap: 5px;
  }
  .footer-bottom-item p {
    font-size: 12px;
    margin-top: 0;
  }
  .footer-bottom-item {
    margin-bottom: 0;
  }
  .footer-bottom {
    grid-template-columns: repeat(2, 1fr);
  }
  .footer-top-left p {
    width: unset;
  }
  .footer-flex {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .footer-items {
    flex-wrap: wrap;
  }
  .footer-items > div {
    width: 45%;
    margin-top: 20px;
  }
  .logo {
    width: unset;
  }
  .footer-item p:nth-child(1) {
    font-size: 16px;
  }
  .footer-item p {
    margin-top: 5px;
    font-size: 13px;
  }
  .logo p {
    font-size: 16px;
    opacity: 0.7;
  }
  .get-started {
    margin-top: 40px;
  }
  .get-started p {
    font-size: 18px;
  }
  .footer-items {
    margin-top: 10px;
  }
  .footer-flex {
    padding-bottom: 30px;
  }
  .footer-items > div {
    margin-top: 10px;
  }
  .get-started img {
    width: 15px;
  }
}
