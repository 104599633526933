 .human-section{
    text-align: center;
    min-height: 100vh;
    margin-top: 100px;
    margin-bottom: 100px;
}

.profile-card{
    /* border:1px solid blue; */
    width: 100%;
}

.profile-card>img{
    width: 100%;
}
.style-subtitle-wrapper-card{
    width:40%;
    margin: auto;
}
.human-text{
  top: 50%;
  width: 100%;
  z-index: 2;
  margin-bottom: 30px;
}

.profile-grid-layout{
    /* border:1px solid red; */
    display: flex;
    justify-content: space-between;
    /* display: grid;
    grid-template-columns: repeat(4,1fr); */
    /* column-gap: 10px; */
    gap: 10px;
    margin: auto;
    width: 90%;
}

.profile-layout{
    /* border:1px solid yellow; */
    display: flex;
    flex-direction: column;
    align-items: center; 
    gap:8px;
} 

/* Responsive styles */
@media (max-width: 1000px) {
    .profile-grid-layout {
      /* border:1px solid blue; */
      display: grid;
      grid-template-columns: repeat(2, 1fr);
    }
    .style-subtitle-wrapper-card{
        width:75%;
        margin: auto;
    }
  }
  
  @media (max-width: 800px) {
    .profile-grid-layout {
        /* border:1px solid yellow;   */
      /* grid-template-columns: repeat(2, 1fr); */
      display: flex;
      flex-direction: column;
       width: 90%;
    }
    .style-subtitle-wrapper-card{
        width:70%;
        margin: auto;
    }
  }
  
  @media (max-width: 600px) {
    .profile-grid-layout {
        /* border:1px solid red; */
        /* display: flex; */
        display: grid;
        grid-template-columns: repeat(2, 1fr);
      /* grid-template-columns: 1fr; */
      /* flex-direction: column;
       width: 80%; */
    }
    .style-subtitle-wrapper-card{
      /* border:1px solid red; */
        width:75%;
    }
  }  