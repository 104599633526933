.HumanAI-section {
    /* border: 1px solid red; */
    text-align: center;
    min-height: 100vh;
}

.HumanAI-section-card {
    /* border: 1px solid red; */
    position: relative;
}

.HumanAI-image {
    /* border: 1px solid red; */
    width: 100%;
    object-fit: cover;
    height: 620px;
}
.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Adjust the transparency here */
  }

.HumanAI-text {
    /* border: 1px solid red; */
    position: absolute;
    top: 80%;
    left: 20%;
    transform: translate(-50%, -50%);
    text-align: left;
    color: white;
}

/* .HumanAI-text>.blur-text {
    position: absolute;
    top: -10%;
    left: 15%;
    transform: translate(-50%, -50%);
    text-align: left;
    color: #999999;
    backdrop-filter: blur(10px);
    background-color: rgba(239, 232, 232, 0.5);
    border-radius: 10px;
    padding: 8px;
} */



.HumanAI-section-card-2 {
    /* border: 1px solid blue; */
    margin: 30px;
}

.HumanAI-section-card-2-Text {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.HumanAI-section-card-2-Text-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}

.HumanAI-section-card-2-Text-1,
.HumanAI-section-card-2-Text-2 {
    /* border: 1px solid red; */
    width: 55%;
}

.HumanAI-section-card-2-Text-1 {
    /* border: 1px solid yellow; */
    text-align: left;
    margin-right: 600px;
    margin-left: -20px;
    /* padding: 50px; */
    padding: 20px 0px 20px 0px;
}

.HumanAI-section-card-2-Text-2 {
    /* border: 1px solid red; */
    text-align: left;
    margin-left: 600px;
    padding: 20px 0px 50px 0;
}


.grid-container {
    /* border: 1px solid blue; */
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    /* margin: 30px; */
    position: relative;
    height: 60vh;
    background-color: #F5F5F5;
}

.GridItem {
    /* border: 1px solid yellow; */
    width: 25%;
    height: 35%;
    /* height: auto; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;
    position: absolute;
    transition: transform 0.3s ease;
    margin: auto;
}

#Item1 {
    top: 20%;
    left: 5%;
    transform: translate(-10%, -20%);
}

#Item2 {
    top: 27%;
    right: 40%;
    transform: translate(50%, -50%);
}

#Item3 {
    bottom: 30%;
    left: 40%;
    transform: translate(-50%, 50%);
}

#Item4 {
    bottom: 30%;
    right: 15%;
    transform: translate(50%, 50%);
}

/* flex-card */
.flex-container {
    /* border: 1px solid blue; */
    background-color: #161616;
    width: 100%;
    padding: 50px 0 50px 0;
}

.flex-card {
    /* border: 1px solid red; */
    display: flex;
    justify-content: space-between;
    width: 90%;
    margin: auto;
    margin-top: 50px;
    margin-bottom: 150px;
}

.flex-container-title-1 {
    /* border: 1px solid red; */
    width: 50%;
    /* margin: auto; */
}

.flex-container-title-2 {
    /* border: 1px solid red; */
    width: 45%;
    /* margin: auto; */
}

.style-flex-title1 {
    font-size: 42px;
    line-height: 126%;
    letter-spacing: -2px;
    font-family: "Poppins", sans-serif;
    color: #FFFFFF;
    font-weight: 400;
    text-align: left;
}

.style-flex-paragraph {
    color: #C7C7C7;
    font-family: Poppins, sans-serif;
    font-size: 20px;
    font-weight: 400;
    letter-spacing: -1px;
    line-height: 150%;
    text-align: left;
}

.flex-card-box {
    display: flex;
    justify-content: space-between;
    width: 90%;
    gap: 20px;
    margin: auto;
    margin-top: 50px;
}

/* last card */
.last-container-of-page{
    /* border: 1px solid red; */
    width: 100%;
    margin-top: 50px;
}

.last-flex-text{
    /* border: 1px solid blue; */
    display: flex;
    justify-content: space-between;
    width: 90%;
    margin: auto;
    gap: 30px;
}
.last-flex-paragraph{
    text-align: left;
    color: #00000080;
    font-family: Poppins,sans-serif;
    font-size: 16px;
    font-weight: 500;
    padding-bottom: 10px;
    padding-left: 10px;
    width: 100%;
}
.last-flex-paragraph span{
    text-decoration: underline;
  align-items: center;
  gap: 10px;
  background-image: linear-gradient(90deg, #5382ed, #ac6eb3, #d36679);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.last-container-title-1, .last-container-title-2{
    /* border: 1px solid red; */
    width: 40%;
    text-align: left;
}
.last-flex-container-of-page{
    display: flex;
    flex-direction: column;
}


/* Responsive adjustments */
@media (max-width: 600px) {
    .HumanAI-text {
        /* border: 1px solid red; */
        top: 80%;
        left: 42%;
        width: 70%;
    }

    .HumanAI-image {
        /* border: 1px solid red; */
        width: 100%;
        object-fit: cover;
        height: 580px;
    }

    /* .HumanAI-text>.blur-text {
        top: -15%;
        left: 39%;
        padding: 6px;
        width: 80%;
    } */

    .HumanAI-section-card-2-Text-container {
        flex-direction: column;
        align-items: center;
    }

    .HumanAI-section-card-2-Text-1,
    .HumanAI-section-card-2-Text-2 {
        width: 100%;
        margin: 0;
    }

    /* .HumanAI-section-card-2>.blur-text {
        width: 80%;
        margin-left: 0;
        padding: 0;
    } */

    .grid-container {
        /* border: 1px solid blue; */
        grid-template-columns: 1fr;
        grid-template-rows: repeat(4, auto);
        padding: 20px;
        height: auto;
    }

    .GridItem {
        /* border: 1px solid yellow; */
        height: auto;
        position: relative;
        width: 100%;
        padding: 0px;
    }

    #Item1,
    #Item2,
    #Item3,
    #Item4 {
        transform: none;
        top: auto;
        bottom: auto;
        left: auto;
        right: auto;
    }

    .flex-card {
        /* border: 1px solid red; */
        flex-direction: column;
        width: 100%;
        margin: auto;
    }

    .flex-container-title-1 {
        width: 100%;
        padding: 15px;
    }
    
    .flex-container-title-2 {
        width: 100%;
        padding: 15px;
        margin-top: 20px;
    }

    
    .flex-card-box {
        /* border: 1px solid blue; */
        flex-direction: column;
        width: 100%;
    }
    /* .last-text-card-blur-text{
        width: 30%;
        margin-left: 21px;
        margin-bottom: 10px;
    } */
    .last-flex-text{
        /* border: 1px solid blue; */
        flex-direction: column;
        width: 90%;
        margin: auto;
        gap: 30px;
    }
    .last-container-title-1, .last-container-title-2{
        /* border: 1px solid red; */
        width: 100%;
        text-align: left;
    }
    .last-flex-paragraph{
        padding-left: 0px;
    }

    .style-flex-title1 {
        font-size: 35px;
        line-height: 126%;
        letter-spacing: -2px;
        font-weight: 400;
    }
    
    .style-flex-paragraph {
        font-size: 16px;
        font-weight: 400;
        letter-spacing: 1px;
        line-height: 150%;
        text-align: left;
    }
}

/* @media (max-width: 800px) {
    .HumanAI-text {
        border: 1px solid red;
        top: 80%;
        left: 45%;
        width: 70%;
    }
    .HumanAI-text >.blur-text {
        border: 1px solid blue;
        top: -15%;
        left: 21%; 
        padding: 6px;
    }
} */

/* @media (max-width: 1100px) {
    .HumanAI-text {
        border: 1px solid red;
        top: 80%;
        left: 45%;
        width: 70%;
    }
    .HumanAI-text >.blur-text {
        border: 1px solid blue;
        top: -15%;
        left: 19%;
        padding: 6px;
    }
} */